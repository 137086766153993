.wholecontainer{
    background: url("./Assets/back.jpg"), lightgray 50% / cover no-repeat;
    background-size: cover;
    width: 100%;
    /* height: 52rem; */

}
.bigcontainer{
    width: 100%;
    height: 100vh;
    display: flex;
    
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    
}

.searchbar{
    padding-left: 1rem;
    width: 37rem;
    height: 4.25rem;
    border-radius: 2rem;
   background: #FFF;
   outline: none;
   border: 1px solid #000;
   text-align: left;
   color: #8E8686;
 font-family: Lato;
 font-size: 1.2rem;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
   

}

body{
    padding: 0;
    margin: 0;
}

.searchbutton{
    width: 4.2rem;
height: 4.2rem;
background-color: #FFF;
border-radius: 50%;
margin-left: 1rem;
cursor: pointer;
}

img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.search{
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
}
.resultcontainer{
    margin-top: 1rem;
    margin-right: 4rem;
    width: 21.875rem;
height: 13.5625rem;
border-radius: 1.5625rem;
background: #FFF;
display: flex;
flex-flow: column wrap;
align-items: center;

}
.searchcontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
}
.thumbdown{
    margin: 2rem;
    width: 6.125rem;
   
height: 5.75rem;

border-radius: 50%;
}
.text{
    color: #000;
font-family: Lato;
font-size: 1.5625rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.loader{
    color: #FFF;
}

.historycontainer{
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    width: 26%;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    background: #FFF;
    align-items: center;
    
}

.urlcard{
    overflow: hidden;
    flex-grow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 20.5rem;
height: 4.0625rem;
border-radius: 0.9375rem;
background: #FFF;
box-shadow: 0px 4px 25px 15px rgba(0, 0, 0, 0.25);
color: #000;
font-family: Lato;
font-size: 0.8rem;
font-style: normal;
font-weight: 700;
line-height: normal;
text-wrap: wrap;
}

a{
    text-decoration: none; 
    background: none; 
  cursor: pointer; 
 color: #000;
  border: none; 
  outline: none; 
  
}

.headercontainer{
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 4.875rem;;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.25); 
    
    background-blend-mode: darken;
    backdrop-filter: blur(17.5px);
align-self: flex-start;
display: flex;
align-items: center;
justify-content: space-between;


}

.closemenu{
   
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.3rem;
    width: 3.125rem;
height: 3.125rem;
border-radius: 50%;
background: #E52E65;

}
.closehum{
    width: 1.5rem;
    height: 1.5rem;
}
.openhistorycontainer{
    z-index: 2;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 4rem;
height: 6rem;
margin-right: 3rem;

}
.historyimg{
    width: 3.125rem;
    height: 3.125rem;
}
.historytxt{
    background: linear-gradient(103deg, #3D5885 -11.1%, #8452CA 107.18%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.logo1{
    margin-left: 3rem;
    width: 9rem;
    font-family: Lato;
font-size: 2.1875rem;
font-style: normal;
font-weight: 700;
line-height: normal;
background: linear-gradient(96deg, #83C0F8 10.23%, rgba(195, 53, 253, 0.62) 77.68%, rgba(219, 0, 255, 0.00) 103.41%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.footercontainer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    margin: auto;
    
    
    text-align: center;
    color: #FFF;
    text-shadow: rgb(18, 18, 16) 1px 0 10px;
    font-family: Lato;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

@media (max-width: 912px) {
    .headercontainer{
        width: 100%;
        z-index: 2;
    }
    .wholecontainer{
        height: auto;
        width: 100%;

    }
    .bigcontainer{
        width: 100%;

    }
    .search{
        width: auto;
        
    }
    .searchcontainer{
        flex-flow: row wrap;
        
    }
    .searchbar{
        margin-left: 0.2rem;
        width: 16rem;
        height: 3.25rem;
    
    }
    .searchbutton{
       z-index: 1;
       margin-left: 0.2rem;
        width: 3.4rem;
        height: 3.4rem;
        
    }
    .resultcontainer{
        margin-right: 0.8rem;
        width: 17rem;
        height: 11rem;

    }
    .logo1{
        margin-left: 1rem;
    }
    .openhistorycontainer{
        margin-right: 1rem;
    }

    .historycontainer{
        
    
       
        width: 14rem;
        height: 100vh;
    }
    .urlcard{
        width: 10.125rem;
height: 2.5625rem;
  overflow: scroll;

    }
    .thumbdown{
        width: 4rem;
   
height: 4rem;
    }

}
